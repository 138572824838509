import React from "react";
import resizeImage from "../../utils/ResizeImage";

const CollectionResult = props => {
  const { result } = props;
  return (
    <a
      className="card_a full_height bordered"
      href={`/collections/${result.handle}`}
    >
      <span className="card_photo_holder">
        <img
          className="card_photo circle-square"
          src={resizeImage(result.imageUrl, "300x300", true, "collection")}
          alt={escape(result.title)}
        />
      </span>
      <span className="pretext">Collection</span>
      <h3>{result.title}</h3>

      <p>
        <span className="price">{result.productCount || "0"} items</span>
      </p>

      <span className="btn btn_clean">Shop</span>
    </a>
  );
};

export default CollectionResult;
