const resizeImage = (imageUrl, size, cropCenter, shopifyType) => {
  if (imageUrl === null) {
    let backupUrl = document.getElementById(`${shopifyType}BackupImage`).value;
    return addResizeParts(backupUrl, size, cropCenter);
  } else {
    return addResizeParts(imageUrl, size, cropCenter);
  }
};

const addResizeParts = (imageUrl, size, cropCenter) => {
  let urlParts = imageUrl.split('/');
  let newUrlParts = [];
  urlParts.forEach((urlPart, index) => {
    if (urlPart.includes('.') && index > 2) {
      let lastPeriod = urlPart.lastIndexOf('.');

      let tempUrlPart = [
        urlPart.slice(0, lastPeriod),
        `_${size}`,
        urlPart.slice(lastPeriod),
      ].join('');

      if (cropCenter) {
        let lastPeriod = tempUrlPart.lastIndexOf('.');

        tempUrlPart = [
          tempUrlPart.slice(0, lastPeriod),
          '_crop_center',
          tempUrlPart.slice(lastPeriod),
        ].join('');
      }

      newUrlParts.push(tempUrlPart);
    } else {
      newUrlParts.push(urlPart);
    }
  });

  return newUrlParts.join('/');
};

export default resizeImage;
