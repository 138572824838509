import React from 'react';
import resizeImage from '../../utils/ResizeImage';

const ArticleResult = props => {
  const { result } = props;
  return (
    <a
      className='card_a full_height bordered'
      href={`/blogs/${result.parentHandle}/${result.handle}`}
    >
      <span className='card_photo_holder'>
        <img
          className='card_photo circle-square'
          src={resizeImage(result.imageUrl, '300x300', true, 'article')}
          alt={escape(result.title)}
        />
      </span>
      <span className='pretext'>
        {new Date(result.publishedAt).toLocaleDateString('en-us', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })}
      </span>
      <h3>{result.title}</h3>

      <span className='rte'>
        <span className='pretext'> by {result.author}</span>
        <p>
          {result.excerpt === null
            ? ''
            : result.excerpt.replace(/<(?:.|\n)*?>/gm, '').substring(0, 80)}
        </p>
      </span>

      <span className='btn btn_clean'>Read More</span>
    </a>
  );
};

export default ArticleResult;
