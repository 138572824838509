import React from 'react';
import SearchResult from '../components/SearchResults/SearchResult';

/*const LoadingSpinner = props => {
  if (props.isLoading) {
    return <div className='loader'>Loading...</div>;
  } else {
    return null;
  }
};*/

const SearchResults = props => {
  const { isError, isLoading, searchResults, searchedFor } = props;

  if (isError && searchResults.length <= 0) {
    return (
      <div className='card_grid'>
        <div className='grid_row'>
          <p>
            There was an error loading your results, please refresh the page.
          </p>
        </div>
      </div>
    );
  } else if (searchResults.length <= 0) {
    if (isLoading) {
      return null;
    } else {
      return (
        <div className='card_grid'>
          <div className='grid_row'>
            <p>We're sorry, there are no results for {searchedFor}</p>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className='card_grid'>
        {searchResults.map((searchResult, index) => (
          <SearchResult
            result={searchResult.document}
            score={searchResult.score}
            shop={props.shop}
            key={index}
            index={index}
          />
        ))}
      </div>
    );
  }
};

export default SearchResults;
