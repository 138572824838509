import React from "react";
import resizeImage from "../../utils/ResizeImage";

const ProductPricing = props => {
  const { result } = props;

  if (props.available) {
    return (
      <p>
        <span
          className={`price ${result.compareAtPrice > 0 ? "price_sale" : ""}`}
          itemProp="offers"
          itemScope
          itemType="http://schema.org/Offer"
        >
          <meta
            itemProp="price"
            content={
              result.price === null ? "Unknown " : result.price.toFixed(2)
            }
          />
          <meta itemProp="priceCurrency" content="USD" />
          <meta itemProp="seller" content={escape(props.shop)} />
          <link itemProp="availability" href="http://schema.org/InStock" />
          <meta itemProp="itemCondition" content="New" />$
          {result.price === null ? "Unknown " : result.price.toFixed(2)}
          <CompareAtPriceDisplay
            CompareAtPrice={result.compareAtPrice}
            Price={result.price}
          />
        </span>
      </p>
    );
  } else {
    return (
      <span
        className="price"
        itemProp="offers"
        itemScope
        itemType="http://schema.org/Offer"
      >
        <meta itemProp="seller" content={escape(props.shop)} />
        <link itemProp="availability" href="http://schema.org/OutOfStock" />
        <meta itemProp="itemCondition" content="New" />
      </span>
    );
  }
};

const CompareAtPriceDisplay = props => {
  if (props.compareAtPrice > 0 && props.compareAtPrice > props.price) {
    return <span className="price_compare">props.compareAtPrice</span>;
  } else {
    return null;
  }
};

const ProductButton = props => {
  if (props.available) {
    return <span className="btn btn_clean">Buy Now</span>;
  } else if (
    props.result.tags
      .join(",")
      .toLowerCase()
      .includes("back-order")
  ) {
    return <span className="price_soldout">Coming Soon</span>;
  } else {
    return <span className="price_soldout">Sold Out</span>;
  }
};

const ProductResult = props => {
  const { result } = props;

  const available = !result.tags
    .join(",")
    .toLowerCase()
    .includes("out of stock");

  return (
    <a
      className="card_a full_height bordered"
      href={`/products/${result.handle}`}
    >
      <span className="card_photo_holder">
        <img
          className="card_photo"
          src={resizeImage(result.imageUrl, "300x300", true, "product")}
          alt={escape(result.title)}
        />
      </span>
      <span className="pretext">{result.vendor}</span>
      <h3>{result.title}</h3>

      <ProductPricing result={result} available={available} />

      <ProductButton result={result} available={available} />
      <span
        className="shopify-product-reviews-badge"
        data-id={result.shopifyId}
      />
    </a>
  );
};

export default ProductResult;
