import React from 'react';
import resizeImage from '../../utils/ResizeImage';

const PageResult = props => {
  const { result } = props;
  return (
    <a className='card_a full_height bordered' href={`/pages/${result.handle}`}>
      <span className='card_photo_holder'>
        <img
          className='card_photo circle-square'
          src={resizeImage(result.imageUrl, '300x300', true, 'page')}
          alt={escape(result.title)}
        />
      </span>
      <h3>{result.title}</h3>

      <span className='rte'>
        <p>
          {result.description === null
            ? ''
            : result.description.replace(/<(?:.|\n)*?>/gm, '').substring(0, 80)}
        </p>
      </span>

      <span className='btn btn_clean'>Read More</span>
    </a>
  );
};

export default PageResult;
