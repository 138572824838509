import React from 'react';
import ArticleResult from './ArticleResult';
import CollectionResult from './CollectionResult';
import PageResult from './PageResult';
import ProductResult from './ProductResult';

const SearchResult = props => {
  if (props.result.shopifyType === 'article') {
    return (
      <div className='card' id={'SearchResult' + props.index}>
        <ArticleResult shop={props.shop} result={props.result} />
      </div>
    );
  } else if (
    props.result.shopifyType === 'smart_collection' ||
    props.result.shopifyType === 'custom_collection'
  ) {
    return (
      <div className='card' key={props.index} id={'SearchResult' + props.index}>
        <CollectionResult shop={props.shop} result={props.result} />
      </div>
    );
  } else if (props.result.shopifyType === 'page') {
    return (
      <div className='card' id={'SearchResult' + props.index}>
        <PageResult shop={props.shop} result={props.result} />
      </div>
    );
  } else if (props.result.shopifyType === 'product') {
    return (
      <div
        className='card'
        id={'SearchResult' + props.index}
        score={props.score}
      >
        <ProductResult shop={props.shop} result={props.result} />
      </div>
    );
  } else {
    return <p>{props.result.shopifyType}</p>;
  }
};

export default SearchResult;
