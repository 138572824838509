import React, { Component } from 'react';
import URLSearchParams from 'url-search-params';
import createHistory from 'history/createBrowserHistory';

import SearchForm from './components/SearchForm';
import SearchResults from './pages/SearchResults';
import Breadcrumbs from './components/Breadcrumbs';
import LoadMoreButton from './components/LoadMoreButton';

//Settings
const apiHost = 'https://searchapp.eformedpartners.com';

const queryParams = window.location.search;
const history = createHistory();

let shop = document.getElementById('shop').value;
if (shop === '-- shop.url --') {
    shop = new URLSearchParams(queryParams).get('shop');
}

const query = new URLSearchParams(queryParams).get('query') || '*';
const shopifytype = new URLSearchParams(queryParams).get('shopifytype') || '';

const SearchedFor = props => {
    if (props.searchedFor === "*") {
        return (<div className='grid_row title marginbottom'>
            <h1 className='small-down--text-center'>
                Search
          </h1>
        </div>)
    } else {
        return (<div className='grid_row title marginbottom'>
            <h1 className='small-down--text-center'>
                Results for "{props.searchedFor}":
          </h1>
        </div>)
    }
}

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            isLoading: false,
            searchedFor: query,
            query: query,
            shopifytype: shopifytype,
            searchResults: [],
            hasMore: true,
            lastPageLoaded: 0,
        };
    }

    componentDidMount() {
        this.setPageTitle(query);
        this.getSearchResults(
            shop,
            this.state.query,
            this.state.shopifytype,
            false,
        );
        this.setHistory();
    }

    setParentState = (fieldName, fieldValue) => {
        this.setState({ [fieldName]: fieldValue });
        if (fieldName === 'query' || fieldName === 'shopifytype') {
            this.instantSearch();
        }
    };

    setPageTitle = query => {
        if (query === '*') {
            document.title = 'Search | Babywise.life';
        } else {
            document.title = 'Search Results for: ' + query;
        }
    };

    getSearchResults = (shop, query, shopifytype, moreBool) => {
        this.setState({ isLoading: true, hasMore: false, searchedFor: query });
        var page = 1;
        if (moreBool === true) {
            page = this.state.lastPageLoaded + 1;
        }

        //console.log('https://advsearchdevtest.azurewebsites.net/api/searchresults.liquid?shop=' + shop + '&query=' + query + '&shopifytype=' + shopifytype + '&page=' + (this.state.lastPageLoaded + 1).toString());
        fetch(
            `${apiHost}/api/searchresults?shop=${shop}&query=${query}&shopifytype=${shopifytype}&page=${page.toString()}`,
        )
            .then(response => {
                if (response.status === 400 || response.status === 500) {
                    throw response;
                } else {
                    return response.json();
                }
            })
            .then(data => {
                //console.log(data);
                if (
                    data.redirect === true &&
                    data.redirectUrl !== null &&
                    data.redirectUrl !== undefined
                ) {
                    window.location.href = `${
                        data.redirectUrl
                        }?query=${query}&searchcat=redirect`;
                } else {
                    let results = data.results;
                    if (results.length > 0) {
                        if (moreBool !== true) {
                            this.setState({ lastPageLoaded: 1, searchResults: [] });
                            this.setHistory();
                        } else {
                            this.setState({ lastPageLoaded: this.state.lastPageLoaded + 1 });
                        }
                        results.forEach(result => this.state.searchResults.push(result));
                        if (results.length > 35) {
                            this.setState({ hasMore: true });
                        }
                    } else {
                        if (moreBool !== true) {
                            this.setState({ lastPageLoaded: 1, searchResults: [] });
                            this.setHistory();
                        }
                        this.setState({ hasMore: false });
                    }
                    this.setState({ isLoading: false });
                    //console.log('# of results: ' + this.state.searchResults.length);
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({ isError: true });
            });
    };

    instantSearch = () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.getSearchResults(
                shop,
                this.state.query,
                this.state.shopifytype,
                '1',
            );
        }, 500);
    };

    setHistory = () => {
        const testParam = new URLSearchParams(queryParams).get('test');
        let path = `${window.location.pathname}?query=${
            this.state.searchedFor
            }&shopifytype=${this.state.shopifytype}&shop=${shop}`;
        if (testParam) {
            path = path + `&test=true`;
        }
        history.replace(path);
    };

    render() {
        return (
            <div id='shopify-section-search' className='shopify-section'>
                <SearchedFor searchedFor={this.state.searchedFor} />
                <SearchForm
                    setParentState={this.setParentState}
                    getSearchResults={this.getSearchResults}
                    shop={shop}
                    isLoading={this.state.isLoading}
                    query={this.state.query}
                    shopifytype={this.state.shopifytype}
                />
                <div className='grid_row copy center'>
                    <SearchResults
                        getSearchResults={this.getSearchResults}
                        shop={shop}
                        isLoading={this.state.isLoading}
                        isError={this.state.isError}
                        searchedFor={this.state.searchedFor}
                        searchResults={this.state.searchResults}
                        hasMore={this.state.hasMore}
                        shopifytype={this.state.shopifytype}
                    />
                    <LoadMoreButton
                        getSearchResults={this.getSearchResults}
                        shop={shop}
                        isLoading={this.state.isLoading}
                        searchedFor={this.state.searchedFor}
                        shopifytype={this.state.shopifytype}
                        hasMore={this.state.hasMore}
                    />
                </div>
                <Breadcrumbs searchedFor={this.state.searchedFor} />
            </div>
        );
    }
}
