import React from 'react';

const LoadMoreButton = props => {
  if (props.isLoading) {
    return <div className='loader'>Loading...</div>;
  } else {
    if (props.hasMore) {
      return (
        <button
          className='btn btn_addtocart'
          disabled={props.isLoading}
          onClick={() =>
            props.getSearchResults(
              props.shop,
              props.searchedFor,
              props.shopifytype,
              true,
            )
          }
        >
          Load More...
        </button>
      );
    } else {
      return null;
    }
  }
};

export default LoadMoreButton;
