import React from 'react';

const Breadcrumbs = props => {
  return (
    <ol
      className='breadcrumbs'
      itemScope=''
      itemType='http://schema.org/BreadcrumbList'
    >
      <li
        itemProp='itemListElement'
        itemScope=''
        itemType='http://schema.org/ListItem'
      >
        <a href='https://www.babyearth.com' title='Baby Earth' itemProp='item'>
          <span itemProp='name'>Home</span>
        </a>
      </li>
      <li
        itemProp='itemListElement'
        itemScope=''
        itemType='http://schema.org/ListItem'
      >
        <a href='/apps/search' itemProp='item' className='breadcrumb_link'>
          <span itemProp='name'>Search</span>
        </a>
      </li>
      <li>{props.searchedFor}</li>
    </ol>
  );
};

export default Breadcrumbs;
