import React from 'react';

const SearchButtonContent = props => {
  /*if (propsisLoading) {
    return (<div className="loader">Loading...</div>)
  }
  else {*/
  return (
    <svg
      className='icon icon-search'
      role='presentation'
      focusable='false'
      viewBox='0 0 20 20'
    >
      <path
        fill='#444'
        d='M18.64 17.02l-5.31-5.31c.81-1.08 1.26-2.43 1.26-3.87C14.5 4.06 11.44 1 7.75 1S1 4.06 1 7.75s3.06 6.75 6.75 6.75c1.44 0 2.79-.45 3.87-1.26l5.31 5.31c.45.45 1.26.54 1.71.09.45-.36.45-1.17 0-1.62zM3.25 7.75c0-2.52 1.98-4.5 4.5-4.5s4.5 1.98 4.5 4.5-1.98 4.5-4.5 4.5-4.5-1.98-4.5-4.5z'
      />
    </svg>
  );
  //}
};

const SearchForm = props => {
  return (
    <div className='block_form grid_row'>
      <b>Search Again:&nbsp;</b>
      <label htmlFor='Search' className='label-hidden' />
      <input
        type='search'
        name='query'
        id='Search'
        value={props.query}
        onChange={event => props.setParentState('query', event.target.value)}
        placeholder=''
      />
      &nbsp;
      <select
        id='shopifytype'
        name='shopifytype'
        value={props.shopifytype}
        onChange={event =>
          props.setParentState('shopifytype', event.target.value)
        }
      >
        <option value=''>Everything</option>
        <option value=''>-----</option>
        <option value='product'>Products only</option>
        <option value='article'>Articles only</option>
        <option value='collection'>Collections only</option>
        <option value='page'>Pages only</option>
      </select>
      &nbsp;
      <button
        type='submit'
        className='btn'
        disabled={props.isLoading}
        onClick={() =>
          props.getSearchResults(
            props.shop,
            props.query,
            props.shopifytype,
            false,
          )
        }
      >
        <SearchButtonContent isLoading={props.isLoading} />
      </button>
    </div>
  );
};

export default SearchForm;
